import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { notify } from '../../utils/notificationHelper';
import { activateCrisis, desactivateCrisis } from '../api/CrisisService';
import PlanningModal from '../modals/PlanningModal';

export default function SiteCard({ accessProfiles, refetchAccessProfiles }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItemName, setSelectedItemName] = useState('');
    const token = useSelector((state) => state.user.token);
    const userID = useSelector((state) => state.user.userDetails.id);
    const [selectedAccessProfile, setSelectedAccessProfile] = useState(null);
    const [numberInCrisis, setNumberInCrisis] = useState(0);
    const [idStatusList, setIdStatusList] = useState([]);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleDesactivateCrisis = async (payload, accessProfile) => {
        notify('Désactivation du mode crise...', 'loading', undefined, true);
        try {
            await desactivateCrisis(payload, token);

            toast.dismiss();
            notify('Mode crise désactivé avec succès.', 'success');

            // delayedNavigate();
            refetchAccessProfiles();
        } catch (error) {
            toast.dismiss();
            notify('Echec de la désactivation.', 'error');
            console.error('Erreur lors de la désactivation de la crise', error);
        }
    };

    const handleActivateCrisis = async (accessProfile) => {
        notify('Activation du mode crise...', 'loading', undefined, true);
        try {
            await activateCrisis({ accessProfiles: [accessProfile], user_id: userID }, token);

            toast.dismiss();
            notify('Mode crise activé avec succès.', 'success');
            // delayedNavigate();
            refetchAccessProfiles();
        } catch (error) {
            toast.dismiss();
            notify("Échec de l'activation du mode crise.", 'error');
            // alert('Failed to schedule crisis mode.');
            console.error('Activation failed:', error.response || error);
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
        }
    };
    const handleGlobalActivateCrisis = async () => {
        const formattedAccessProfiles = accessProfiles?.map((acess) => ({
            identity: acess.identity,
            name: acess.name,
            description: acess.description,
            domain: acess.domain,
            deleted: acess.deleted,
        }));
        notify('Activation du mode crise global...', 'loading', undefined, true);
        try {
            await activateCrisis(
                { accessProfiles: formattedAccessProfiles, user_id: userID },
                token
            );

            toast.dismiss();
            notify('Mode crise global activé avec succès.', 'success');
            // delayedNavigate();
            refetchAccessProfiles();
        } catch (error) {
            toast.dismiss();
            notify("Échec de l'activation du mode crise global", 'error');
            console.error('Global activation failed:', error.response || error);
        }
    };

    const handleGlobalDesactivateCrisis = async () => {
        notify('Désactivation du mode crise global...', 'loading', undefined, true);
        try {
            const payload = {
                [userID.toString()]: idStatusList,
            };

            await desactivateCrisis(payload, token);
            toast.dismiss();
            notify('Mode crise global désactivé avec succès.', 'success');
            // delayedNavigate();
            refetchAccessProfiles();
        } catch (error) {
            toast.dismiss();
            console.error('Global desactivation failed:', error.response || error);
        }
    };

    useEffect(() => {
        const sitesWithIdStatut = accessProfiles?.filter(
            (profile) => profile.status && profile.status !== 'DEACTIVATION_IN_PROGRESS'
        );
        const idStatutList = sitesWithIdStatut?.map((profile) => profile.id_status);
        // Set the state with this new list of id_statut values
        setIdStatusList(idStatutList);
        // Count the filtered sites
        setNumberInCrisis(sitesWithIdStatut?.length);
    }, [accessProfiles]);

    const getItemStatus = (item) => {
        if (item.status === 'ACTIVATED') {
            return (
                <h3 className={`font-semibold text-xl text-red-500`}>
                    Statut: Activé <FontAwesomeIcon className='ml-2 inline' icon={faLock} />{' '}
                </h3>
            );
        }
        if (item.status === 'ACTIVATION_IN_PROGRESS') {
            return (
                <h3 className={`font-semibold text-xl text-orange-500`}>
                    {`Activation en cours${
                        item.processedKeys
                            ? `: ${item.removed + item.trusted}/${item.processedKeys}`
                            : ''
                    }`}
                </h3>
            );
        }
        if (item.status === 'DEACTIVATION_IN_PROGRESS') {
            return (
                <h3 className={`font-semibold text-xl text-orange-500`}>
                    Désactivation en cours: {item.processedKeys - (item.removed + item.trusted)}/
                    {item.processedKeys}
                </h3>
            );
        }

        return (
            <h3 className={`font-semibold text-xl text-green-500`}>
                Statut: Désactivé <FontAwesomeIcon className='ml-2 inline' icon={faUnlock} />{' '}
            </h3>
        );
    };

    return (
        <div className='container mx-auto p-4 '>
            <div className='text-right mb-10 w-full mx-auto'>
                {accessProfiles?.length > 0 &&
                    (numberInCrisis && numberInCrisis > 0 ? (
                        <button
                            className='px-2 py-2 my-3 rounded text-lg text-white bg-trust-blue'
                            onClick={handleGlobalDesactivateCrisis}
                        >
                            Désactiver le mode crise globalement
                        </button>
                    ) : (
                        <button
                            className='px-2 py-2 my-3 rounded text-lg text-white bg-red-500'
                            onClick={handleGlobalActivateCrisis}
                        >
                            Activer le mode crise globalement
                        </button>
                    ))}
            </div>
            <div className='w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6'>
                {accessProfiles?.map((item) => (
                    <div
                        key={item.identity}
                        className={`h-full bg-white rounded shadow-md ${
                            item.status === 'ACTIVATED'
                                ? 'shadow-red-500'
                                : !item.status || item.status === 'DEACTIVATED'
                                ? 'shadow-green-500'
                                : 'shadow-orange-500'
                        }  p-4`}
                    >
                        <div className='text-center h-full'>
                            <div className=' h-2/4 '>
                                <p className='text-trust-blue text-lg mb-3 '>{item.description}</p>
                                <h2 className='text-md font-semibold '>{item.name}</h2>
                            </div>
                            <div className='mt-4'>{getItemStatus(item)}</div>
                            <div className='flex justify-center align-bottom space-x-4 mt-3 mb-3 '>
                                {item.status === 'ACTIVATED' && (
                                    <button
                                        className=' px-2 py-2 rounded  text-sm text-white bg-trust-blue'
                                        onClick={() => {
                                            const payload = {
                                                [userID.toString()]: [item.id_status],
                                            };
                                            const accessProfile = {
                                                identity: item.identity,
                                                name: item.name,
                                                description: item.description,
                                                domain: item.domain,
                                                deleted: item.deleted,
                                            };

                                            handleDesactivateCrisis(payload, accessProfile);
                                        }}
                                    >
                                        Désactiver
                                    </button>
                                )}
                                {(!item.status || item.status === 'DEACTIVATED') && (
                                    <button
                                        className=' px-2 py-2 rounded  text-sm text-white bg-red-500 disabled:bg-gray-500'
                                        onClick={() => {
                                            const accessProfile = {
                                                identity: item.identity,
                                                name: item.name,
                                                description: item.description,
                                                domain: item.domain,
                                                deleted: item.deleted,
                                            };
                                            handleActivateCrisis(accessProfile);
                                        }}
                                        disabled={
                                            item.status === 'ACTIVATION_IN_PROGRESS' ||
                                            item.status === 'DEACTIVATION_IN_PROGRESS'
                                        }
                                    >
                                        Activer
                                    </button>
                                )}

                                {(!item?.status || item.status === 'DEACTIVATED') && (
                                    <button
                                        className=' bg-vivid-blue hover:bg-vivid-blue bg-transparent border text-sm rounded px-2 py-2 disabled:bg-gray-500 disabled:text-white'
                                        onClick={() => {
                                            const accessProfile = {
                                                identity: item.identity,
                                                name: item.name,
                                                description: item.description,
                                                domain: item.domain,
                                                deleted: item.deleted,
                                            };
                                            setSelectedAccessProfile(accessProfile);
                                            setSelectedItemName(item.name);
                                            setIsModalOpen(true);
                                        }}
                                    >
                                        Plannifier
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                ))}

                {isModalOpen && (
                    <PlanningModal
                        onClose={closeModal}
                        name={selectedItemName}
                        token={token}
                        accessProfile={selectedAccessProfile}
                    />
                )}
            </div>
        </div>
    );
}
