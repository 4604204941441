import React from 'react'
import { Link } from 'react-router-dom'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faUser } from '@fortawesome/free-solid-svg-icons';
import Logo from './logo.png'
export default function AdminNav() {
  return (
        <>
    <nav className="py-2 px-8 bg-trust-blue font-bold text-white">
        <div className="flex justify-between items-center">
            <div className="hidden md:flex space-x-4">
            <Link to="/admin/dashboard">Accueil</Link>
            </div>
            <div className="flex-grow"></div>
            <div className="hidden md:flex space-x-4">
            <a href="https://dcwm02.abloy.com/CLIQWebManager" target="blank"> CLIQWebManager</a>
            <Link to="/login" >
                Déconnexion
            </Link>
            </div>
        </div>
        </nav>
        <div className="py-2 px-4 flex justify-between items-center border mb-6 bg-white">
        <div className="md:flex space-x-4">
        
        {/* <Link to='/History' > Historique Crise </Link> */}
      
    
        </div>
        <div className=" flex flex-row space-x-4">
        <Link to ="/Profile">
        <div className='mr-5 flex space-x-4  my-auto'>
            <p>Super Admin 🔐</p>
            {/* <FontAwesomeIcon icon={faUser} className='my-auto' />  */}
        </div>
        </Link>

            {/* <p>{userName}</p> */}
        
        <div className='ml-16'>
            <img src={Logo} alt="Logo" className="h-8 mr-4" />
        </div>
    
        </div>
    </div>
    </>
  )
}
