import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/features/user/userSlice'; // Ajustez le chemin d'accès selon votre structure de dossiers
import SecondNavbar from "./SecondNavbar";


export default function Navbar() {
  const dispatch = useDispatch();
  const handleLogoutClick = () => {
    // Appeler la fonction de déconnexion du contexte lorsque l'utilisateur clique sur le lien de déconnexion
    dispatch(logout());
  };
  return (
    <>
    <nav className="py-2 px-8 bg-trust-blue font-bold text-white ">
      {/* bg-slate-100 */}
      <div className="flex justify--between items-center ">
        <div className="hidden md:flex space-x-4">
          <Link to="/home">Accueil</Link>
        </div>
        <div className="flex-grow"></div>
        <div className="hidden md:flex space-x-4">
          <a href="https://dcwm02.abloy.com/CLIQWebManager" target="blank"> CLIQWebManager</a>
          <Link to="/login" onClick={handleLogoutClick} >
            Déconnexion
          </Link>
        </div>
      </div>
    </nav>
      <SecondNavbar />
      </>
  );
}
