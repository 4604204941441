import { toast } from 'react-hot-toast';

export const notify = (message, type = 'default', duration = 4000, isLoading = false) => {
  // Common options for all toast types
  const options = {
    duration, // Set the duration the toast should last. Default is 4000ms (4 seconds).
  };


  switch (type) {
    case 'success':
      toast.success(message, options);
      break;
    case 'error':
      toast.error(message, options);
      break;
    case 'info':
      toast(message, options);
      break;
    case 'loading':
      if (isLoading) {
        // For loading, ignore the duration by setting it to null
        toast.loading(message, { ...options, duration: null });
      } else {
        toast.dismiss(); // You may want to use a specific toast ID to dismiss the correct toast.
      }
      break;
    default:
      toast(message, options);
  }
};

