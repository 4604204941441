import React from 'react';

export default function AdminSummary({ userCount }) {
 
  const modeCrisesAdded = '-';//20;
  const modeCrisesDeleted = '-';//10;
  

  return (
    <div className="bg-white p-4 rounded-lg shadow-md text">
      {/* Admin Summary Card */}
      <h2 className="text-xl font-semibold mb-2">Vue d'ensemble</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-center">
        {/* User Count */}
        <div className="bg-gray-100 p-4 rounded-md">
          <h3 className="text-lg font-semibold mb-2">Number of Users</h3>
          <p className="text-3xl font-bold text-trust-blue">{userCount}</p>
        </div>
        {/* Mode Crises Added */}
        <div className="bg-gray-100 p-4 rounded-md">
          <h3 className="text-lg font-semibold mb-2">Mode Crises Activated</h3>
          <p className="text-3xl font-bold text-trust-blue">{modeCrisesAdded}</p>
        </div>
        {/* Mode Crises Deleted */}
        <div className="bg-gray-100 p-4 rounded-md">
          <h3 className="text-lg font-semibold mb-2">Mode Crises Deleted</h3>
          <p className="text-3xl font-bold text-trust-blue">{modeCrisesDeleted}</p>
        </div>
      </div>
     
    </div>
  );
}
