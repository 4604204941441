import { jwtDecode } from 'jwt-decode';

// Fonction pour vérifier si le token est expiré
export const isTokenExpired = (token) => {
  if (!token) {
    return true;
  }
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Temps actuel en secondes
    return decoded.exp < currentTime;
  } catch (error) {
    return true;
  }
};

// Fonction pour obtenir le token JWT du stockage local
export const getToken = () => {
  return localStorage.getItem('token');
};

// Fonction pour définir le token JWT dans le stockage local
export const setToken = (token) => {
  localStorage.setItem('token', token);
};

// Fonction pour supprimer le token JWT du stockage local
export const removeToken = () => {
  localStorage.removeItem('token');
};

// Fonction pour ajouter le token JWT dans les en-têtes des requêtes sortantes
export const attachTokenToHeaders = (headers = {}) => {
  const token = getToken();
  if (token && !isTokenExpired(token)) {
    return { ...headers, Authorization: `Bearer ${token}` };
  }
  return headers;
};
