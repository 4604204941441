import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ConfirmDeleteModal from '../modals/ConfirmDeleteModal';
import { deleteUser } from '../api/Api';
import { notify } from '../../utils/notificationHelper';
import { toast } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
  export default function UsersTable({users}){

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const navigate = useNavigate();
    const delayedNavigate = () => {
      setTimeout(() => {
        navigate(0);
      }, 1000); 
    };

    const handleDeleteUser = async (userId) => {
      notify(`Suppression de l'utilisateur en cours...`, 'loading', undefined, true);
      try {
        await deleteUser(userId);
       
        toast.dismiss()
        notify('Utilisateur supprimé avec succès.', 'success');
        delayedNavigate()
      } catch (error) {
        console.error(`Failed to delete user with ID ${userId}:`, error);
        toast.dismiss()
        notify("Échec de la suppresion de l'utilisateur", 'error');
      }
      setIsModalOpen(false);
      setUserIdToDelete(null);
     
    };
    const promptDeleteUser = (userId) => {
      setIsModalOpen(true);
      setUserIdToDelete(userId);
      console.log(userId)
    };

  
    const handleCloseModal = () => {
      setIsModalOpen(false);
      setUserIdToDelete(null);
    };
  return (
    <div className='bg-white p-4 rounded-lg shadow-md mt-6'>
    <h2 className='text-slate-950 text-2xl'>Liste des administrateurs </h2>
    <div className='text-right my-3'>
    <Link to="/Admin/AddUser" className='text-trust-blue my-2 mx-2 py-2 px-2 rounded-lg uppercase font-bold'> <FontAwesomeIcon icon={faPlus} className='my-auto' /> Ajouter un admin</Link>
    </div>
    <ConfirmDeleteModal 
        isOpen={isModalOpen} 
        onClose={handleCloseModal} 
        onConfirm={() => handleDeleteUser(userIdToDelete)} 
      />
    <div className=' text-left mx-auto border text-lg max-h-96 overflow-auto'>
    <table className='w-full '>
    <thead>
      <tr className='border bg-trust-blue text-white' >
        
        <th>Prénom</th>
        <th>Nom de famille</th>
        <th>Email </th>
        <th>Roles</th>
        <th>Domaines attribuées</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      {users.map((user) => (
        <tr className='border py-2' key={user.id}>
         
          <td className=' '>{user.firstName}</td>
          <td>{user.surname}</td>
          <td>{user.email}</td>
          {/* undefined */}
          {/* <td>{user.enabled ? 'Yes' : 'No'}</td> */}
          <td className='max-w-xs truncate overflow-hidden text-ellipsis'>{user.roles.join(', ')}</td>
          <td className='max-w-xs '>{user.domainName.join(', ')}</td>
          <td className='text-center py-3'>
          {!user.roles.includes("SUPER_ADMIN") && (
              <button onClick={() => promptDeleteUser(user.id)}  className='bg-red-500 text-white py-1 px-3 hover:text-red-700 rounded'>
                <FontAwesomeIcon icon={faTrash} />
                </button>
                )}
              </td>
        </tr>
      ))}
    </tbody>
  </table>
  </div>
  </div>
  )
}
