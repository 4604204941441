import React, { useEffect} from 'react';
import './App.css';
import { Route, Routes, Navigate} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Home from './components/Pages/Home';
import Login from './components/Pages/Login'
import ArchivePage from './components/Pages/ArchivePage';
import Profile from './components/Pages/Profile';
import NotFound from './components/Pages/NotFound';
import Forbidden from './components/Pages/Forbidden';
import DashboardAdmin from './components/Pages/DashboardAdmin';
import { setAuthenticated } from './redux/features/user/userSlice';
import AddUser from './components/admin/AddUser';
import { isTokenExpired } from './components/api/tokenUtils'; 
import { Toaster } from 'react-hot-toast';
import PasswordReset from './components/Pages/PasswordReset';

 
function App() {
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const userRoles = useSelector(state => state.user.roles);
  const dispatch = useDispatch();
  const ProtectedRoute = ({ element, requiredRoles }) => {
    const isAuthorized = isAuthenticated && requiredRoles.some(role => userRoles.includes(role));
    return isAuthorized ? element : <Navigate to="/forbidden" />;
  };
  
  const navigate = useNavigate();
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && !isTokenExpired(token)) {
      dispatch(setAuthenticated(true));
    } else {
      dispatch(setAuthenticated(false));
    }
  }, [dispatch, navigate]);

   
  return (
      <div className="App">
        <div className="">
          <div className="flex">
            <div className="content w-full ">
              <Routes>
                <Route path="/Admin/Dashboard" element={<ProtectedRoute element={<DashboardAdmin />} requiredRoles={['ROLE_SUPER_ADMIN']} />} />
                <Route path="/Admin/AddUser" element={<ProtectedRoute element={<AddUser />} requiredRoles={['ROLE_SUPER_ADMIN']} />} />
                <Route path="/home" element={<ProtectedRoute element={<Home />} requiredRoles={['ROLE_ENEDIS_LEVEL_1','ROLE_RTE_LEVEL_1', 'ROLE_RTE_LEVEL_2']} />} />
                <Route path="/profile" element={<ProtectedRoute element={<Profile />} requiredRoles={['ROLE_ENEDIS_LEVEL_1', 'ROLE_RTE_LEVEL_1', 'ROLE_RTE_LEVEL_2', 'ROLE_SUPER_ADMIN']} />} />
                <Route path="/history" element={<ProtectedRoute element={<ArchivePage />} requiredRoles={['ROLE_ENEDIS_LEVEL_1', 'ROLE_RTE_LEVEL_1','ROLE_RTE_LEVEL_2']} />} />
                <Route path="/forbidden" element={<Forbidden />} />
                <Route path="/reset-password" element={<PasswordReset />} />
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
              <Toaster />
            </div>
          </div>
        </div>
      </div>
   
  );
}

export default App;
