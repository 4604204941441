import React, {useState} from 'react'
import { useSelector } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { scheduleCrisis } from '../api/CrisisService';
import { notify } from '../../utils/notificationHelper';

export default function PlanningModal({ onClose , name, token, accessProfile}) {
    const [activationDateTime, setActivationDateTime] = useState(new Date());
    const userID=  useSelector((state)=> state.user.userDetails.id)
    // // Gets the current date and time +1H
    const oneHourFromNow = new Date();
    oneHourFromNow.setHours(oneHourFromNow.getHours() + 1);
    const filterPassedTime = (time) => {
      const currentDate = new Date();
      const selectedDate = new Date(time);
  
      return currentDate.getDate() === selectedDate.getDate() &&
             currentDate.getMonth() === selectedDate.getMonth() &&
             currentDate.getFullYear() === selectedDate.getFullYear() ?
             oneHourFromNow : new Date().setHours(0, 0, 0, 0);
    };

    const handleScheduleCrisis = async () => {
      // Assuming `params` is an object containing necessary data to schedule the crisis
      const params = {
        accessProfiles: [accessProfile],
        user_id: userID,
      };
    
      try {
        await scheduleCrisis(activationDateTime, params, token);
        const activationDateStr = activationDateTime.toLocaleString('fr-FR', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          // second: '2-digit',
        });
        
        notify(`Mode crise activé avec succès pour le ${activationDateStr}.`, 'success');
        onClose(); // Consider closing the modal on successful operation
      } catch (error) {
        notify('Échec de l\'activation du mode crise.', 'error');
        console.error('Error scheduling crisis:', error.response || error);
      }
    };
  
    return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Fond semi-transparent */}
      <div className="fixed inset-0 bg-gray-900 opacity-70"></div>
      {/* Contenu du modal */}
      <div className="relative z-10 bg-white w-1/4 p-4 rounded-lg shadow-md text-center">
        {/* Titre du modal */}
        <h2 className="text-xl font-semibold mb-4 items-center">Planification du mode crise</h2>
        <h3 className='text-2xl font-bold mb-12'>{name}</h3>
        {/* Contenu du modal */}
     
      <div className="space-y-6 flex flex-col w-full  text-center  ">
      <table className="">
            <tbody className=''>
              <tr>
                <td className="text-right  w-1/2">Date de début :</td>
                <td className=''>
                  <DatePicker
                    selected={activationDateTime}
                    onChange={(date) => setActivationDateTime(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Heure"
                    dateFormat="dd/MM/yyyy HH:mm:ss"
                    minDate={new Date()} // Sets minimum day as today
                    minTime={filterPassedTime(activationDateTime)}
                    maxTime={new Date().setHours(23, 45)} // Sets the maximum time selection to 23:45
                    
                    
                    className="w-full px-3 py-2 border rounded-md text-left"
                  />
                </td>
              </tr>
             
            </tbody>
          </table>
          <div className="flex justify-end space-x-4">
        <button
          className="bg-zinc-500  text-white hover:bg-gray-400 text-gray-800 text-sm py-2 px-2 rounded focus:outline-none focus:ring focus:ring-gray-300"
          onClick={onClose}
          >
          Fermer
        </button>
        <button className="bg-red-500 text-white  hover:bg-vivid-dark text-sm rounded px-2 py-2  "
        onClick={handleScheduleCrisis}>
              Plannifier
        </button>
       
        </div>
    
      </div>
        
    </div>
    </div>
  )
}
