// features/user/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userDetails:  [],
    isAuthenticated: false,
    token: null,
    roles: [],
    domains: [],
    isUserDataLoaded: false, 
  },
  reducers: {
    login: (state, action) => {
      state.userDetails = action.payload.userDetails;
      state.token = action.payload.token;
      state.roles = action.payload.roles;
      state.domains = action.payload.domains;
      state.isAuthenticated = true;
      state.error = null;
      state.isUserDataLoaded = true; 
      // Store token in session storage
      // sessionStorage.setItem('token', action.payload.token);
    },
    logout: (state) => {
      state.userDetails = [];
      state.isAuthenticated = false;
      state.token = null
      state.roles = [];
      state.domains = []
      state.isUserDataLoaded = false;
      // localStorage.removeItem('token');
      // sessionStorage.removeItem('token');
    },
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    loginFailure: (state, action) => {
      state.error = action.payload;
     
    },
    // redirectToLogin: () => {
    //   // Cette action est un placeholder pour déclencher la redirection. Elle ne modifie pas directement l'état.
    // },
  },
});

export const { login, logout, setAuthenticated, redirectToLogin } = userSlice.actions;
export const selectUser = (state) => state.user;

export default userSlice.reducer;
