import React from 'react'
import AddUserForm from './Form/AddUserForm'
import AdminNav from '../admin/AdminNav'

export default function () {
  return (
    <div>
        <AdminNav/>
        <AddUserForm />

    </div>
  )
}
