import React from "react";
import { useSelector } from "react-redux";
import Navbar from "../Navbar/Navbar";
import AdminNav from "../admin/AdminNav";

export default function Profile() {
  const userDetails = useSelector((state)=> state.user.userDetails)
  const keysToDisplay = {
    'firstName': 'Prénom',
    'surname': 'Nom',
    'email': 'Email',
    'domainName': 'Domaines'
  };
  const email = userDetails ? userDetails.email : null;
  const fullname = userDetails ? userDetails.firstName + ' ' + userDetails.surname : null;
  return (
    <>
    {userDetails.roles.includes('SUPER_ADMIN') ? <AdminNav />:<Navbar />}
    <div>
      {email ? (
        <div className="">
            <div className="text-center mb-8 mt-8">
            <h3 className="text-4xl ">Vos Informations:</h3>
              <p className="text-3xl mt-6 "> {fullname}</p>
              <p className="text-xl mt-6 "> {userDetails.roles }</p>
            </div>
          
          <div className="w-1/4 mx-auto">
            <table className="bg-white border rounded text-center mx-auto w-full ">
              <tbody className="">
              {Object.entries(keysToDisplay).map(([key, label]) => (
                <tr className="border text-left" key={key}>
                    <td className="p-3 text-trust-blue ">{label}</td> 
                    <td className="max-w-xs truncate overflow-hidden text-ellipsis">
                      
                      {key === 'domainName'
                        ? userDetails[key].length === 0
                          ? "Vous n'avez aucun domaine attribué" // Affichez cela si le tableau est vide
                          : userDetails[key].length === 1
                            ? userDetails[key][0] // S'il n'y a qu'un seul domaine, affichez-le sans virgule
                            : userDetails[key].join(', ') // S'il y a plusieurs domaines, rejoignez-les avec des virgules
                        : userDetails[key]}
                    
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <h2>Loading...</h2>
        )}
    </div>
    </>
  );
}
