import React, { useState } from 'react';
import axios from 'axios';
import Logo from './Abloy_Logo_blue.png';
import { notify } from '../../utils/notificationHelper';
import { toast } from 'react-hot-toast';
import { useNavigate } from "react-router-dom";

export default function PasswordReset() {
  const [email, setEmail] = useState('');
  const apiUrl = `${process.env.REACT_APP_API_URL}/auth/changePass`;
  const navigate = useNavigate();

  const delayedNavigate = () => {
    setTimeout(() => {
      navigate('/login', {replace: true});
    }, 1000); 
  };
  
  const handleResetPassword = async (e) => {
    e.preventDefault();
   
    try {
      await axios.put(apiUrl, { mail: email }, { headers: { 'Content-Type': 'application/json' } });
      toast.dismiss()
      notify('Un e-mail de réinitialisation a été envoyé.', 'success');
      delayedNavigate()
      
    } catch  {
      toast.dismiss()
      notify("Erreur lors de l'envoi de l'e-mail");
      
    }
  };
  
  return (
      <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <img src={Logo} alt="Logo" className="mb-20 h-16 mx-auto" />
          <h2 className="mt-6 text-center text-4xl font-extrabold text-gray-900">
            Mot de passe oublié ?
          </h2>
          <p className="text-center text-sm text-gray-600">
            Nous allons vous envoyer un e-mail contenant votre nouveau mot de passe.
          </p>
          <form className="mt-8 space-y-6" onSubmit={handleResetPassword}>
            <input
              className="appearance-none rounded-none relative block w-full px-3 py-2 mb-4 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              id="email"
              type="email"
              placeholder="Adresse e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md bg-vivid-blue hover:bg-vivid-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              type="submit"
            >
              Envoyer
            </button>
          </form>
            <div className="text-right">
              <button
                className="text-trust-blue hover:text-indigo-900 text-sm font-semibold"
                onClick={() => navigate('/login', {replace: true})} // Retour à la page précédente
              >
                Retour à la connexion
              </button>
            </div>
         
        </div>
      </div>
  );
}
