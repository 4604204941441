import React from 'react'
import Logo from './logo.png'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons';

export default function SecondNavbar() {
  //userName = localStorage.getItem('data')
  return (
    <div className="py-2 px-4 flex justify-between items-center border mb-6 bg-white">
    <div className="md:flex space-x-4">
    <Link to='/home' > Mes domaines </Link>
    <Link to='/History' > Historique Crise </Link>
    {/* <Link to ="/Agents"> Agents </Link> */}
    {/* <Link to ="/ArchiveList">  Historique  </Link> */}
   
    </div>
    <div className=" flex flex-row space-x-4">
    <Link to ="/Profile">
      <div className='mr-5 flex space-x-4  my-auto'>
        <p>Mon compte</p>
         <FontAwesomeIcon icon={faUser} className='my-auto' /> 
      </div>
      </Link>

        {/* <p>{userName}</p> */}
      
      <div className='ml-16'>
        <img src={Logo} alt="Logo" className="h-8 mr-4" />
      </div>
   
    </div>
  </div>
  )
}
