import React, { useState, useEffect } from 'react';
import AdminNav from '../admin/AdminNav'
import AdminSummary from '../admin/AdminSummary'
import UsersTable from '../admin/UsersTable'
import { fetchUsers } from '../api/Api';


export default function DashboardAdmin() {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const loadUsers = async () => {
      try {
        const users = await fetchUsers(); 
     
      setUsers(users);

      } catch (error) {
        console.error("Failed to fetch users:", error);
        
      }
    };

    loadUsers();
  }, []);

  return (
  <>
    <AdminNav /> 
    <div className="container mx-auto">
    {users.length ? (
      <>
        <AdminSummary userCount={users.length}/>
        <UsersTable users={users} />
      </>
    ) : (
      <div>No users found.</div>
    )}
    </div>
  </>
  )
}
