import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import userReducer from './features/user/userSlice';
// Import other reducers

// Combine all reducers into a single root reducer
const rootReducer = combineReducers({
  user: userReducer,
  // Add other reducers here
});

const persistConfig = {
  key: 'root',
  storage,
  // Not specifying whitelist or blacklist persists everything
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
  // Add any middleware or enhancers here
});

export const persistor = persistStore(store);
