import React from 'react'

export default function NotFound() {
  return (
    <div className='flex flex-col items-center justify-center h-screen '>
      <h2 className='text-4xl text-center '>404 - Page not found</h2>
      <p className='text-center mt-10'>La page que vous recherchez n'existe pas.</p>

    </div>
  )
}
