import axios from 'axios';
import { attachTokenToHeaders } from './tokenUtils';

const apiUrl = process.env.REACT_APP_API_URL;

const fetchStatusesFromAPI = async () => {
    try {
        const headers = attachTokenToHeaders({
            'Content-Type': 'application/json',
        });
        const response = await axios.get(
            // "http://localhost:8082/histo/checkStatus",
            `${apiUrl}/histo/checkStatus`,
            {
                headers,
            }
        );

        // Assuming the API response structure is an array of statuses
        return response.data;
    } catch (error) {
        console.error('Error fetching statuses:', error);
        throw error; // Rethrowing the error or handling it as needed
    }
};

const chunk = (array, size) =>
    Array.from({ length: Math.ceil(array?.length / size) }, (v, i) =>
        array?.slice(i * size, i * size + size)
    );

const fetchAccessProfiles = async (domains) => {
    try {
        const headers = attachTokenToHeaders({
            'Content-Type': 'application/json',
        });

        const chunkArrays = chunk(domains, 10);

        const accessProfiles = [];

        await axios.all(
            chunkArrays?.map((array) => {
                return axios
                    .get(`${apiUrl}/api/getAccessProfiles`, {
                        params: { array },
                        headers,
                        paramsSerializer: (params) => {
                            return `domains=${params.array.join(',')}`; // Custom serialization
                        },
                    })
                    .then((data) => {
                        data.data.map((accessProfile) => accessProfiles.push(accessProfile));
                    })
                    .catch((error) => {});
            })
        );

        return accessProfiles;
    } catch (error) {
        console.error("Erreur lors de la récupération des profils d'accès:", error);
    }
};

// const crisisStatuses = await fetchStatusesFromAPI();

// // Associate each access profile with a crisis status
// const profilesWithStatus = accessProfiles?.map((profile) => {
//     const matchingCrisisStatus = crisisStatuses.find(
//         (crisisStatus) => crisisStatus.name === profile.name
//     );
//     return {
//         ...profile,
//         status: matchingCrisisStatus ? 'incrisis' : 'notcrisis',
//         id_status: matchingCrisisStatus ? matchingCrisisStatus.id_status : null,
//     };
// });

const fetchUsers = async () => {
    try {
        const headers = attachTokenToHeaders({
            'Content-Type': 'application/json',
        });

        const response = await axios.get(
            `${apiUrl}/user/all`,
            // "http://localhost:8082/user/all",
            {
                headers,
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
    }
};

const deleteUser = async (userId) => {
    try {
        const headers = attachTokenToHeaders({
            'Content-Type': 'application/json',
        });

        const response = await axios.delete(
            // `http://localhost:8082/user/delete?userId=${userId}`,
            `${apiUrl}/user/delete?userId=${userId}`,
            {
                headers,
            }
        );

        return response.data;
    } catch (error) {
        console.error(`Error deleting user:`, error);
        throw error;
    }
};

export { deleteUser, fetchAccessProfiles, fetchStatusesFromAPI, fetchUsers };
