import React from 'react'

export default function Forbidden() {
  return (
    <div className='flex flex-col items-center justify-center h-screen '>
    <h2 className='text-4xl text-center '>403 - Accès interdit</h2>
    <p className='text-center mt-10'>Vous n'avez pas la permission d'accéder à cette page</p>
  </div>
  )
}
