import React ,{useEffect} from 'react'
import axios  from 'axios';

import { useSelector } from 'react-redux'; 

import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom'; 
import { attachTokenToHeaders } from '../../api/tokenUtils';
import { notify } from '../../../utils/notificationHelper'; 
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';

export default function AddUserForm() {
     const token = useSelector((state) => state.user.token); 
     const navigate = useNavigate();
     const apiUrl = process.env.REACT_APP_API_URL

    const validationSchema = Yup.object({
      firstName: Yup.string().required('Le prénom est requis'),
      surname: Yup.string().required('Le nom est requis'),
      email: Yup.string().email('Adresse email invalide').required('L’email est requis'),
      roles: Yup.string().required('Le rôle est requis'),
      domainName: Yup.array().min(1, 'Au moins 1 domaine doit être sélectionné').required('Domain name is required'),
      
    });
    
    const formik = useFormik({
        initialValues: {
          firstName: '',
          surname: '',
          email: '',
          roles:'',
          domainName: [],
          createdDate: new Date().toISOString().slice(0, 19)
        },
        validationSchema,
        onSubmit: async (values) => {
          try {
            const headers = attachTokenToHeaders({
              'Content-Type': 'application/json',
            });
            // Construct the payload with specific fields, excluding domainNameOptions
            const payload = {
              firstName: values.firstName,
              surname: values.surname,
              email: values.email,
              roles: [values.roles], // Assuming roles are expected as an array
              domainName: values.domainName, // Keep this field
              createdDate: values.createdDate,
            };
            notify('Création du user en cours...', 'loading', undefined, true);
            await axios.post(
              // 'http://localhost:8082/user/add',
              `${apiUrl}/user/add`,
               payload, { headers });
            toast.dismiss()
            notify('Utilisateur ajouté avec succès.', 'success');
            navigate('/Admin/Dashboard');
          } catch (error) {
            console.error('Failed to add user', error);
            toast.dismiss()
            notify("Échec de l'ajout d'un utilisateur", 'error');
          }
        },
        
      });

      const handleDomainNameChange = (event) => {
        const { value, checked } = event.target;
        const { domainName } = formik.values;
       
    
        if (checked) {
          // Add the value to the array
          formik.setFieldValue('domainName', [...domainName, value]);
        } else {
          // Remove the value from the array
          formik.setFieldValue('domainName', domainName.filter((domain) => domain !== value));
        }
      };

    useEffect(() => {
      const fetchDomains = async () => {
        try {
          const headers = attachTokenToHeaders();
          const response = await axios.get(
            `${apiUrl}/api/getDomains`,
            // 'http://localhost:8082/api/getDomains', 
            { headers });
          const domains = response.data.map((domain) => domain.name);
          formik.setFieldValue('domainNameOptions', domains);
        } catch (error) {
          console.error('Failed to fetch domain names', error);
        }
      };
    
      if (token) {
        fetchDomains();
      }
    }, [token]);
    
    
  return (
    <div className="max-w-md mx-auto bg-white p-8 rounded-lg shadow-md">
  <form onSubmit={formik.handleSubmit} className="space-y-4">
    <div>
      <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">Prénom</label>
      <input
        id="firstName"
        name="firstName"
        type="text"
        onChange={formik.handleChange}
        value={formik.values.firstName}
        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
      />
    </div>
    {formik.errors.firstName && formik.touched.firstName ? (
          <div className="text-red-500 text-sm">{formik.errors.firstName}</div>
        ) : null}

    <div>
      <label htmlFor="surname" className="block text-sm font-medium text-gray-700">Nom</label>
      <input
        id="surname"
        name="surname"
        type="text"
        onChange={formik.handleChange}
        value={formik.values.surname}
        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
      />
    </div>
    {formik.errors.surname && formik.touched.surname ? (
          <div className="text-red-500 text-sm">{formik.errors.surname}</div>
        ) : null}

    <div>
      <label htmlFor="email" className="block text-sm font-medium text-gray-700">Adresse email</label>
      <input
        id="email"
        name="email"
        type="email"
        onChange={formik.handleChange}
        value={formik.values.email}
        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
      />
    </div>
    {formik.errors.email && formik.touched.email ? (
          <div className="text-red-500 text-sm">{formik.errors.email}</div>
        ) : null}
   
    <div>
      <label htmlFor="roles" className="block text-sm font-medium text-gray-700">Roles</label>
      <select
    id="roles"
    name="roles"
    onChange={formik.handleChange}
    value={formik.values.roles}
    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
  >
    <option value="" disabled>Choisir un role </option>
    <option value="ENEDIS_LEVEL_1">ENEDIS</option>
    <option value="RTE_LEVEL_1">RTE_LEVEL_1</option>
    <option value="RTE_LEVEL_2">RTE_LEVEL_2</option>

  </select>
    </div>
    {formik.errors.roles && formik.touched.roles ? (
          <div className="text-red-500 text-sm">{formik.errors.roles}</div>
        ) : null}

    <div>
          <label htmlFor="domainName" className="block text-sm font-medium text-gray-700">Domaines attribués</label>
          <div className="mt-1 w-full border border-gray-300 rounded-md shadow-sm overflow-y-scroll" style={{height: "150px"}}>
          {formik.values.domainNameOptions && formik.values.domainNameOptions.map((domain) => (
              <div key={domain} className="ml-2">
                <input
                  id={domain}
                  name="domainName"
                  type="checkbox"
                  value={domain}
                  onChange={handleDomainNameChange}
                  checked={formik.values.domainName.includes(domain)}
                  className="form-checkbox"
                />
                <label htmlFor={domain} className="ml-2 text-sm font-medium text-gray-700">{domain}</label>
              </div>
            ))}
          </div>
          </div>
          {formik.errors.domainName && formik.touched.domainName ? (
          <div className="text-red-500 text-sm">{formik.errors.domainName}</div>
        ) : null}

    <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-trust-blue hover:bg-blue-950 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
      Enregistrer
    </button>
  </form>
</div>

  
  )
}
