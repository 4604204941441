// Assurez-vous que attachTokenToHeaders est importé
import axios from "axios";
import { attachTokenToHeaders } from './tokenUtils';


const apiUrl = process.env.REACT_APP_API_URL

function convertDate(dateString) {
  const date = new Date(dateString);
  const offset = date.getTimezoneOffset() * 60000; // Offset in milliseconds
  const localDate = new Date(date.getTime() - offset);
  return localDate.toISOString().slice(0, 19);
}

const activateCrisis = async (params) => {
    const headers = attachTokenToHeaders({
      'Content-Type': 'application/json',
    });

    try {
       
  
        const response = await axios.post(`${apiUrl}/api/protected/activateCrisis`, params, {
          headers
        });
        
        return response.data;
    } catch (error) {
      console.error("Problème lors de l'activation de la crise :", error.response || error);
      throw error;
    }
};

const desactivateCrisis = async(params) =>{
  const headers = attachTokenToHeaders({
    'Content-Type': 'application/json',
  });
  try {
    const response = await axios.post(`${apiUrl}/api/protected/stopCrisis`, params, {
      headers
    });
    
    return response.data;
} catch (error) {
  console.error("Problème lors de désactivation de la crise :", error.response || error);
  throw error;
}
}

const scheduleCrisis = async (dateToActivate, params) => {
    const date = convertDate(dateToActivate);
    try {
       
        const headers = attachTokenToHeaders({
          'Content-Type': 'application/json',
        });

        const response = await axios.post(`${apiUrl}/api/protected/planCrisis`, params, {
          headers,
          params: { date }
        });
        return response.data;
        
    } catch (error) {
        console.error('Scheduling failed:', error.response || error);
        throw error;
    }
};

export { activateCrisis, desactivateCrisis, scheduleCrisis };
