import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import axios from 'axios';
import { useSelector } from 'react-redux';

export default function ArchivePage() {
  const [HistoryData, setHistoryData] = useState([]);
  const token = useSelector((state) => state.user.token);
  const apiUrl = process.env.REACT_APP_API_URL

  const formatDateSimple = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${day}/${month}/${year} ${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };
  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${apiUrl}/histo/all`,
        {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setHistoryData(response.data);
      console.log(response.data)
    
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <>
      <Navbar />
      <div className="container mx-auto p-4 w-full ">
        <h3 className='text-2xl font-bold mb-12 text-trust-blue'>
          Archives des situations de crise :
        </h3>
      </div>
      <div>

<div className=' px-16'>
        <div className="shadow-md rounded  mx-auto overflow-y-auto max-h-[700px]  ">
          <table className="min-w-max w-full table-auto ">
            <thead className='sticky top-0'>
              <tr className="bg-slate-200 text-sm leading-normal">
                <th className="py-3 px-6 ">Site affecté</th>
                <th className="py-3 px-6 ">Crise</th>
                <th className="py-3 px-6 ">Début situation critique</th>
                <th className="py-3 px-6 ">Activé par</th>
                <th className="py-3 px-6 ">Rôle</th>
                <th className="py-3 px-6 ">Fin situation critique</th>
                <th className="py-3 px-6 ">Terminé par</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 text-sm font-light bg-white">
              {HistoryData.map((Histo, index) => (
                <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6  whitespace-nowrap max-w-xs truncate overflow-hidden text-ellipsis">{Histo.site || "-"}</td>
                  <td className="py-3 px-6 whitespace-nowrap max-w-xs truncate overflow-hidden text-ellipsis" style={{ color: Histo.live ? 'red' : 'green' }}>
                    {Histo.live ? "Activé" : "Désactivé"}
                  </td>                  
                  <td className="py-3 px-6  whitespace-nowrap max-w-xs truncate overflow-hidden text-ellipsis">{formatDateSimple(Histo.activatedAt) || "-"}</td>
                  <td className="py-3 px-6  whitespace-nowrap max-w-xs truncate overflow-hidden text-ellipsis">{Histo.activatedBy?.email || "-"}</td>
                  <td className="py-3 px-6  whitespace-nowrap max-w-xs truncate overflow-hidden text-ellipsis">
                    {Histo.activatedBy && Histo.activatedBy.authorities && Histo.activatedBy.authorities.length > 0 ? Histo.activatedBy.authorities[0].authority: '-'}
                  </td>                
                  <td className="py-3 px-6  whitespace-nowrap max-w-xs truncate overflow-hidden text-ellipsis">{formatDateSimple(Histo.endedAt)|| "-"}</td>
                  <td className="py-3 px-6  whitespace-nowrap max-w-xs truncate overflow-hidden text-ellipsis">{Histo.endedBy?.email || "-"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </div>
      </div>
    </>
  );
}
