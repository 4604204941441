import React, { useState} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Logo from './logo.png'
import { useDispatch } from "react-redux";
import { login } from "../../redux/features/user/userSlice";
import { notify } from "../../utils/notificationHelper";


export default function Login({ onLogin }) {
  const [showPassword, setShowPassword] = useState(false);
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL
  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!mail || !password) {
      setError("Veuillez saisir le nom d'utilisateur et le mot de passe.");
      return;
    }
  
    try {
      const response = await axios.post(
        `${apiUrl}/auth/login`,
        { mail, password },
        { headers: { "Content-Type": "application/json" } }
      );
      const token = response.data;
      const userResponse = await axios.get(
        `${apiUrl}/user/infos`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
    
      const userDetails = userResponse.data;
      const domains = userResponse.data.domainName
      const roles = userResponse.data.authorities.map(auth => auth.authority);
      
      localStorage.setItem('token', token);
      sessionStorage.setItem('token', token);

      dispatch(login({ userDetails, token, domains,  roles }));
    
      if (roles.includes('ROLE_SUPER_ADMIN')) {
        navigate("/Admin/Dashboard");
        notify(`Bienvenue ${userDetails.firstName}!`, 'success');
      } else {
       navigate("/home")
       notify(`Bienvenue ${userDetails.firstName}!`, 'success');
      }
    } catch (error) {
      console.error("Erreur d'authentification", error);
      notify('Vos identifiants sont incorrect. ', 'error');
    }
  };
  // if (showPasswordReset) {
  //   return <PasswordReset />;
  // }

  return (
    <>
      <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
        <img src={Logo} alt="Logo" className="mb-20 h-24 mx-auto" />
          <div>
            <h2 className="mt-6 text-center text-4xl font-extrabold text-gray-900">
              Connexion
            </h2>
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleLogin}>
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label className=" sr-only" htmlFor="mail">
                  Identifiant
                </label>
                <input
                  className="appearance-none rounded-none relative block w-full px-3 py-2 mb-4 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  id="mail"
                  name="mail"
                  type="email"
                  placeholder="Identifiant"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                  required
                  aria-label="Mot de passe"
                  autoComplete="username" 
                />
              </div>
              <div className="relative">
                <label className="sr-only" htmlFor="password">
                  Mot de passe
                </label>
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Mot de passe"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  aria-label="Mot de passe" 
                  autoComplete="current-password" 
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 flex items-center px-2 focus:outline-none"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <svg
                      className="h-6 w-6 text-gray-700"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="h-6 w-6 text-gray-700"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17.6 17.6a8 8 0 10-11.2-11.2"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <button
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md 
          bg-vivid-blue hover:bg-vivid-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                type="submit"
              >
                Se connecter
              </button>

            </div>

          </form>
          <div className="text-right">
              <button
                className="text-trust-blue hover:text-indigo-900 text-sm font-semibold"
                onClick={() => navigate('/reset-password', {replace: true})}
              >
                Mot de passe oublié?
              </button>
            </div>
          {error && <p className="text-red-500 text-xs italic">{error}</p>}
        </div>
      </div>
    </>
  );
}
