import React from 'react'

export default function ConfirmDeleteModal({ isOpen, onClose, onConfirm }) {
    if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div className="bg-white p-4 rounded-lg">
          <h2>Voulez-vous vraiment supprimer cet utilisateur ?</h2>
          <div className="flex justify-end space-x-4 mt-4">
            <button onClick={onClose} className="py-2 px-4 rounded bg-gray-200">Annuler</button>
            <button onClick={onConfirm} className="py-2 px-4 rounded bg-red-500 text-white">Supprimer</button>
          </div>
        </div>
      </div>
  )
}

