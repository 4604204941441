import React from 'react';
import Navbar from '../Navbar/Navbar';
import { useSelector } from 'react-redux';
import { fetchAccessProfiles, fetchStatusesFromAPI } from '../api/Api';
import SiteCard from '../Cards/SiteCard';
import { useQuery } from '@tanstack/react-query';

const STATUS_CHECK_INTERVAL = 2 * 1000;

export default function Homee() {
    const domains = useSelector((state) => state.user.domains);

    const {
        data: accessProfiles,
        isLoading,
        isError,
        refetch: refetchAccessProfiles,
    } = useQuery({
        queryKey: ['accessProfiles', domains],
        queryFn: () => fetchAccessProfiles(domains),
        enabled: Boolean(domains?.length),
        refetchOnWindowFocus: false,
    });

    const { data: crisisStatuses, refetch: refetchStatus } = useQuery({
        queryKey: ['crisisStatuses'],
        queryFn: fetchStatusesFromAPI,
        enabled: Boolean(domains?.length),
        refetchInterval: STATUS_CHECK_INTERVAL,
        refetchOnWindowFocus: false,
    });

    const refetchData = () => {
        refetchAccessProfiles();
        refetchStatus();
    };

    const profilesWithStatus =
        accessProfiles
            ?.map((profile) => {
                const matchingCrisisStatus =
                    crisisStatuses?.find((crisisStatus) => crisisStatus.name === profile.name) ??
                    null;

                const statusData = matchingCrisisStatus
                    ? {
                          status: matchingCrisisStatus?.status,
                          processedKeys: matchingCrisisStatus?.processedKeys ?? 0,
                          removed: matchingCrisisStatus?.removed ?? 0,
                          trusted: matchingCrisisStatus?.trusted ?? 0,
                      }
                    : {};

                return {
                    id_status: matchingCrisisStatus ? matchingCrisisStatus.id_status : null,
                    ...statusData,
                    ...profile,
                };
            })
            .sort((a, b) => {
                return a?.description.localeCompare(b?.description);
            }) ?? [];

    return (
        <>
            <Navbar />
            <div className='container mx-auto p-4 '>
                <h1 className='text-trust-blue text-2xl'>Liste de mes postes électriques</h1>

                {isLoading ? (
                    <p className='mt-12'>Chargement de vos profils d'accès...</p>
                ) : isError ? (
                    <p className='mt-12'>
                        Une erreur s'est produite lors de la récupération des profils d'accès.
                    </p>
                ) : (
                    <SiteCard
                        accessProfiles={profilesWithStatus}
                        refetchAccessProfiles={refetchData}
                    />
                )}
            </div>
        </>
    );
}
